export default `
<h2>Betonkonstruktioner</h2>
<p>Beton anvendes bl.a. til støbning af fundamenter, gulve, vægge, dæk og trapper. Beton består af cement, sand, sten og vand. Blandingsforholdet mellem disse bestanddele afhænger af, hvilken eksponering konstruktionen udsættes for.</p>

<h2>Opmuring</h2>
<p>Opmuring anvendes typisk til vægkonstruktioner både indendørs og udendørs. Til opmuring anvendes typisk mursten eller letklinkerblokke, som bindes sammen af en mørtel. Mørtel består af bindemiddel, sand og vand. Bindemidlet kan være cement, kalk eller lignende hydraulisk binder. Der skelnes typisk mellem en K-mørtel (ren kalk mørtel), med procentangivelse af kalkindholdet og en C-mørtel (cementmørtel). Normalt anvendes en blanding af de to typer, som kaldes en KC-mørtel. Blandingsforholdet mellem disse bestanddele afhænger af, hvilken eksponering konstruktionen udsættes for.</p>

<h2>Pudsning</h2>
<p>Pudsning anvendes typisk til vandskuring/sækkeskuring, berapning, samt almindeligt puds og mønsterpuds både indendørs og udendørs. Til pudsning af overflader anvendes typisk en mørtel svarende til den ønskede overfladekarakter. Mørtel består af bindemiddel, sand og vand. Bindemidlet kan være cement, kalk eller lignende hydraulisk binder. Der skelnes typisk mellem en K-mørtel (ren kalk mørtel), med procentangivelse af kalkindholdet og en C-mørtel (cementmørtel). Normalt anvendes en blanding af de to typer, som kaldes en KC-mørtel. Blandingsforholdet mellem disse bestanddele afhænger af, hvilken eksponering konstruktionen udsættes for.</p>

<h2>Slid- & Afretningslag</h2>
<p>Slidlag og afretningslag anvendes som det øverste lag i en gulvkonstruktion af beton for at beskytte betonen mod slid og sikre en vandret overflade. Er lagtykkelsen større end 4-5 cm anbefales der i stedet en gulvbeton med 4-8 mm sten og tilsvarende styrke. Til slidlag eller afretningslag anvendes en C-Mørtel (cementmørtel). Mørtlen består af cement, sand og vand. Blandingsforholdet mellem disse bestanddele afhænger af, hvilken eksponering konstruktionen udsættes for.</p>`