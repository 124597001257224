<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            languages: 'GET_ALL_LANGUAGES',
            currentLang: 'GET_CURRENT_LANG'
        })
    },
    methods: {
        switchLanguage(val){
            this.$store.commit('switchLanguage',val)
        }
    }
}
</script>
<style>

#nav {
  padding: 30px 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#language-switcher{
  float: right;
  margin: 0px;
}

#language-switcher li{
  font-weight: bold;
  color: #2c3e50;
  text-transform: uppercase;
  list-style: none;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

#language-switcher li.active {
  color: #42b983;
}

</style>
