import lingual from '@/languages/lingual'
import matrix from '@/matrix'

export default {
    allLanguages: Object.keys(lingual),
    currentLanguageCode: "da",
    lingual: lingual,
    matrix: matrix,
    base: {
        length: "",
        height: "",
        depth: "",
        m3: ""
    }
}