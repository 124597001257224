let type1 = require('@/assets/sidebar/Grafik_ikke-baerende-murvaerk.png');
let type2 = require('@/assets/sidebar/Grafik_baerende-murvaerk.png');
let type3 = require('@/assets/sidebar/Grafik_svaert-belastet-murvaerk.png');

export default `
<h2>Ikke bærende murværk</h2>
<div class="row">
    <div class="col">
        <p>Indvendigt murværk i bygninger med lille risiko for påvirkning af fugt. Tidligere passiv miljøklasse.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type1}" />
    </div>
</div>
<h2>Bærende murværk</h2>
<div class="row">
    <div class="col">
        <p>Indvendigt murværk påvirket af vanddamp. <br/>Udvendigt murværk udsat for kraftig fugtpåvirkning. <br/>Udvendigt murværk udsat for frost/tø-cykler. <br/>Tidligere moderat miljøklasse.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type2}" />
    </div>
</div>
<h2>Svært belastet murværk</h2>
<div class="row">
    <div class="col">
        <p>Udvendigt murværk udsat for kraftig fugtpåvirkning. <br/>Udvendigt murværk udsat for kraftige frost/tø-cykler. <br/>Murværk i kystområder. <br/>Murværk udsat for vejsalt. <br/>Murværk i kontakt med signifikante mængder sulfater og fugt i grundvand eller jord. <br/>Murværk i direkte eller luftbåren kontakt med kemikalier. <br/>Tidligere aggressiv miljøklasse.</p>
    </div>
    <div class="col">
        <img width="247" height="143" src="${type3}" />
    </div>
</div>`