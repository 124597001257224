<template>
	<div id="deeplink-popup">
		<div v-on:click="$parent.closeDeeplinkPopup" class="closeDeeplinkPopupBtn">
			<div class="text">Luk</div>
			<div class="icon">+</div>
		</div>
		<div class="inner-deeplink-popup-content">
			<h2>Deeplink</h2>
			<p>Kopier linket nedenfor, hvis du ønsker at linke direkte ind i beregneren til dennes position.</p>
			<input v-model="deeplinkString" @focus="$event.target.select()" />
		</div>
	</div>
</template>

<script>
export default {
	name: "DeeplinkPopup",
	props: ['deeplinkString']
}
</script>
