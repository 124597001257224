let type1 = require('@/assets/sidebar/Grafik_afretning-slidlag-kaelder-garage.png');
let type2 = require('@/assets/sidebar/Grafik_Anden-belaegning-og-fodergange.png');
let type3 = require('@/assets/sidebar/Grafik_industri-lagerhaller.png');
let type4 = require('@/assets/sidebar/Grafik_slidag-fodergange.png');
let type5 = require('@/assets/sidebar/Grafik_slidag-fodergange_2.png');

export default `
<h2>Kælder, garage</h2>
<div class="row">
    <div class="col">
        <p>Slid- og afretningslag til kælder- og garagegulve.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type1}" />
    </div>
</div>
<h2>Afretningslag til anden belægning (træ og klinker)</h2>
<div class="row">
    <div class="col">
        <p>Underlag til andre belægninger såsom trægulve eller klinker. <br/></p>
    </div>
    <div class="col">
        <img width="216" height="123" src="${type2}" />
    </div>
</div>
<h2>Industrihaller, Lagerhaller</h2>
<div class="row">
    <div class="col">
        <p>Industrihaller med øget slid fra maskinel transport. <br/>Lagerhaller er ofte påvirket af køretøjer med høj belastning. Dette slider på gulvet og kræver derfor øget styrke.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type3}" />
    </div>
</div>
<h2>Slidlag i stier og båse</h2>
<div class="row">
    <div class="col">
        <p>Slidlag i landbrugskonstruktioner udsat for kemiske påvirkninger såsom stier og båse kræver øget tæthed af betonen.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type4}" />
    </div>
</div>
<h2>Slidlag (fodergange)</h2>
<div class="row">
    <div class="col">
        <p>Slidlag i fodergange ved landbrugskonstruktioner.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type5}" />
    </div>
</div>`