let type1 = require('@/assets/sidebar/Grafik_betongulv-kraftigt-slid.png');
let type2 = require('@/assets/sidebar/Grafik_konstruktioner-paavirket-af-vandtryk.png');
let type3 = require('@/assets/sidebar/Grafik_betongulve-nogen-slid.png');
let type4 = require('@/assets/sidebar/Grafik_anden-belaegning.png');
let type5 = require('@/assets/sidebar/Grafik_vaegge-soejler-bjaelker.png');
let type6 = require('@/assets/sidebar/Grafik_underbeton.png');
let type7 = require('@/assets/sidebar/Grafik_terazzobeton.png');
let type8 = require('@/assets/sidebar/Grafik_Belaegninger.png');
let type9 = require('@/assets/sidebar/Grafik_trapper.png');
let type10 = require('@/assets/sidebar/Grafik_vaegge-soejler-bjaelker-udendoers.png');
let type11 = require('@/assets/sidebar/Grafik_udstoebning-fundamentsblokke.png');
let type12 = require('@/assets/sidebar/Grafik_fundamenter-stoerre.png');
let type13 = require('@/assets/sidebar/Grafik_fundamenter-parcel.png');
let type14 = require('@/assets/sidebar/Grafik_fundamenter-delvist over.png');
let type15 = require('@/assets/sidebar/Grafik_klaplag-renselag.png');

export default `
<h2>Udstøbning (fundamentsblokke)</h2>
<div class="row">
    <div class="col">
        <p>Fundamentsblokke til fundamenter eller kældervægge skal efter opstilling på en afmærket placering støbes ud med beton. Der kan være krav til armering, alt efter konstruktionens udformning og placering.</p>
    </div>
    <div class="col">
        <img width="133" height="102" src="${type11}" />
    </div>
</div>
<h2>Fundamenter (større bygninger)</h2>
<div class="row">
    <div class="col">
        <p>Fundamenter til større bygninger.</p>
    </div>
    <div class="col">
        <img width="153" height="120" src="${type12}" />
    </div>
</div>
<h2>Fundamenter (parcelhuse)</h2>
<div class="row">
    <div class="col">
        <p>Fundamenter til parcelhuse.</p>
    </div>
    <div class="col">
        <img width="153" height="109" src="${type13}" />
    </div>
</div>
<h2>Fundamenter (delvist over terræn)</h2>
<div class="row">
    <div class="col">
        <p>Fundamenter som er delvist over terræn kan være direkte påvirket af skadelige udendørs eksponeringer. Der kræves en tættere beton i denne kategori.</p>
    </div>
    <div class="col">
        <img width="153" height="109" src="${type14}" />
    </div>
</div>
<h2>Vægge, søjler og bjælker (indendørs)</h2>
<div class="row">
    <div class="col">
        <p>Indendørs betonkonstruktioner uden direkte fugtpåvirkning. Vær opmærksom på at betonkonstruktioner som har en bærende eller afstivende rolle for konstruktionens stabilitet, skal eftervises at have en tilstrækkelig bæreevne.</p>
    </div>
    <div class="col">
        <img width="148" height="140" src="${type5}" />
    </div>
</div>
<h2>Vægge, søjler og bjælker (udendørs)</h2>
<div class="row">
    <div class="col">
        <p>Udendørs betonkonstruktioner uden afskærmning mod direkte fugtpåvirkning. Vær opmærksom på at betonkonstruktioner som har en bærende eller afstivende rolle for konstruktionens stabilitet, skal eftervises at have en tilstrækkelig bæreevne.</p>
    </div>
    <div class="col">
        <img width="181" height="140" src="${type10}" />
    </div>
</div>
<h2>Underbeton</h2>
<div class="row">
    <div class="col">
        <p>Indendørs underbeton til slidlag og andre belægning, fx klinker, trægulve, mm.</p>
    </div>
    <div class="col">
        <img width="186" height="123" src="${type6}" />
    </div>
</div>
<h2>Trapper</h2>
<div class="row">
    <div class="col">
        <p>Trapper er udsat for øget færdsel og ofte udsat for vejsalt, hvilket kræver en tættere beton.</p>
    </div>
    <div class="col">
        <img width="161" height="112" src="${type9}" />
    </div>
</div>
<h2>Konstruktioner påvirket af vandtryk</h2>
<div class="row">
    <div class="col">
        <p>Denne type konstruktion er jævnligt eller konstant i kontakt med vand. Dette kan eksempelvis være vandbassiner.</p>
    </div>
    <div class="col">
        <img width="140" height="123" src="${type2}" />
    </div>
</div>
<h2>Terrazzobeton</h2>
<div class="row">
    <div class="col">
        <p>Kosmetisk beton, der udelukkende består af cement og skærver. Kræver efterfølgende slibning. Denne betontype består oftest af cementpasta (cement og vand) og sten over 4mm. </p>
    </div>
    <div class="col">
        <img width="171" height="109" src="${type7}" />
    </div>
</div>
<h2>Belægninger</h2>
<div class="row">
    <div class="col">
        <p>Belægninger i form af forskellige typer belægningssten eller støbte betonplader kan bruges i haven, indkørslen eller terrassen. Gangbare belægninger er ofte udsat for vejsalt, hvilket kræver en tættere beton.</p>
    </div>
    <div class="col">
        <img width="247" height="117" src="${type8}" />
    </div>
</div>
<h2>Betongulve (kraftigt slid)</h2>
<div class="row">
    <div class="col">
        <p>Kraftigt slid på gulvkonstruktioner kan forekomme i eksempelvis:<br/>Stier og båse til landbrug<br/>Industri- og lagerhaller udsat for hårde hjul</p>
    </div>
    <div class="col">
        <img width="178" height="115" src="${type1}" />
    </div>
</div>
<h2>Betongulve (moderat slid)</h2>
<div class="row">
    <div class="col">
        <p>Moderat slid på gulvkonstruktioner kan forekomme i eksempelvis:<br/>Garager og køregang med bløde hjul</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type3}" />
    </div>
</div>
<h2>Betongulve (let slid)</h2>
<div class="row">
    <div class="col">
        <p>Let slid på gulvkonstruktioner kan forekomme i eksempelvis:<br/>Gange og arealer uden mekanisk påvirkning</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type4}" />
    </div>
</div>
<h2>Klaplag, Renselag</h2>
<div class="row">
    <div class="col">
        <p>Betonlag som ikke har en bærende eller afstivende rolle for konstruktionens stabilitet. Kan støbes direkte på terræn som et jorddækkende betonlag. Renselaget danner altså et fast underlag for midlertidigt færdsel i forbindelse byggeriets øvrige faser, eksempelvis armeringsarbejde.</p>
    </div>
    <div class="col">
        <img width="216" height="145" src="${type15}" />
    </div>
</div>`