<template>
	<ul class="global-links">
		<li><div class="vejledning-disclaimer" v-on:click="$parent.openIntroPopup">Disclaimer</div></li>
		<li><a class="open-new-window" target="_blank" href="https://beregner.aalborgportland.dk">Åben ny beregning</a></li>
		<li><a class="vidensbasen-link" target="_blank" href="https://www.aalborgportland.dk/vidensbase/">Se vores vidensbase</a></li>
	</ul>
</template>

<script>
export default {
	name: "GlobalLinks"
}
</script>

<style scoped>

</style>
