<template>
	<div class="visualHouse">
		<transition name="fade">
			<img v-if="constructionType !== ''" :src='visualHouseImage' />
		</transition>
		<transition name="fade">
			<img v-if="constructionType === ''" :src='visualHouseImage' />
		</transition>
	</div>
</template>

<script>
export default {
	name: "VisualHouseGraphic",
	props: ['constructionType', 'visualHouseImage']
}
</script>
