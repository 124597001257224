let halv = require('@/assets/sidebar/Murtype_halv.png')
let hel = require('@/assets/sidebar/Murtype_hel.png')
let halvanden = require('@/assets/sidebar/Murtype_en-en-halv.png')
let dobbelt = require('@/assets/sidebar/Murtype_to.png')

export default `
<div class="row">
    <div class="col">
        <h2>Halvstensmur</h2
        <p>Murens tykkelse som angivet ved figuren nedenfor</p>
        <img width="77" height="81" src="${halv}" />
    </div>
</div>
<div class="row">
    <div class="col">
        <h2>Helstensmur</h2>
        <p>Murens tykkelse som angivet ved figuren nedenfor</p>
        <img width="133" height="82" src="${hel}" />
    </div>
</div>
<div class="row">
    <div class="col">
        <h2>Halvandenstensmur</h2>
        <p>Murens tykkelse som angivet ved figuren nedenfor</p>
        <img width="210" height="82" src="${halvanden}" />
    </div>
</div>
<div class="row">
    <div class="col">
        <h2>Dobbeltstensmur</h2>
        <p>Murens tykkelse som angivet ved figuren nedenfor</p>
        <img width="265" height="83" src="${dobbelt}" />
    </div>
</div>
<!--<div class="row">
    <div class="col">
        <h2>Bloksten 10 cm</h2>
    </div>
    <div class="col">
        <h2>Bloksten 20 cm</h2>
    </div>
</div>
<div class="row">
    <div class="col">
        <h2>Bloksten 25 cm</h2>
    </div>
    <div class="col">
        <h2>Bloksten 30 cm</h2>
    </div>
</div>-->`