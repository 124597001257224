let type1 = require('@/assets/sidebar/Grafik_indvendig-puds.png');
let type2 = require('@/assets/sidebar/Grafik_puds-baerende-murvaerk.png');
let type3 = require('@/assets/sidebar/Grafik_puds-svaert-belastet-murvaerk.png');

export default `
<h2>Ikke bærende murværk</h2>
<div class="row">
    <div class="col">
        <p>Overflader på indvendigt murværk i bygninger med lille risiko for påvirkning af fugt. Tidligere passiv miljøklasse.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type1}" />
    </div>
</div>
<h2>Bærende murværk</h2>
<div class="row">
    <div class="col">
        <p>Overflader på indvendigt murværk påvirket af vanddamp. <br/>Overflader på udvendigt murværk udsat for kraftig fugtpåvirkning. <br/>Overflader på udvendigt murværk udsat for frost/tø-cykler. <br/>Tidligere moderat miljøklasse.</p>
    </div>
    <div class="col">
        <img width="151" height="115" src="${type2}" />
    </div>
</div>
<h2>Svært belastet murværk</h2>
<div class="row">
    <div class="col">
        <p>Overflader på udvendigt murværk udsat for kraftig fugtpåvirkning. <br/>Overflader på udvendigt murværk udsat for kraftige frost/tø-cykler. <br/>Overflader på murværk i kystområder. <br/>Overflader på murværk udsat for vejsalt. <br/>Overflader på murværk i kontakt med signifikante mængder sulfater og fugt i grundvand eller jord. <br/>Overflader på murværk i direkte eller luftbåren kontakt med kemikalier. <br/>Tidligere aggressiv miljøklasse.</p>
    </div>
    <div class="col">
        <img width="250" height="143" src="${type3}" />
    </div>
</div>`